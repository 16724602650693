import React from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import book1 from '../img/MACHINE-LEARNING-COVER-PAGE-768x993_11zon.webp';
import book2 from '../img/mastering in pyton_11zon.webp';
import book3 from '../img/NATURAL-LANGUAGE-PROCESSING-COVER-768x994_11zon.webp';
import book4 from '../img/POWER-BI-COVER-PAGE-768x994_11zon.webp';
import book5 from '../img/POWER-BI-VISUALS-COVER-768x994_11zon.webp';
import book6 from '../img/R-BASIC-TO-ADVANCE-768x991_11zon.webp';
import book7 from '../img/SQL-BASIC-TO-ADVANCE-COVER-PAGE-768x994_11zon.webp';
import book8 from '../img/TIME-SERIES-IN-PYTHON-COVER-768x993_11zon.webp';
import book9 from '../img/VBA-COVER-PAGE-768x993_11zon.webp';
import book10 from '../img/VISUALIZATION-COVER-PAGE-768x987_11zon.webp';


const books = [
  { src: book1, title: 'Machine Learning',text: "Discover the transformative power of Machine Learning. This guide takes you through practical algorithms and real-world applications, turning raw data into meaningful predictions. Perfect for anyone looking to make impactful decisions with data." },
  { src: book2, title: 'Mastering Python',text:'Explore the world of Python programming with ease. This book covers everything from writing efficient code to automating tasks, helping you develop powerful applications. Ideal for those eager to advance their data skills.' },
  { src: book3, title: 'Natural Language Processing',text: 'Step into the fascinating realm of NLP. Learn how machines understand human language and build your own text-processing models. This guide is perfect for anyone curious about enhancing their data capabilities.'},
  { src: book4, title: 'Power BI',text:'Create stunning visual stories with Power BI. Learn to design insightful dashboards and reports that transform data into actionable intelligence. This guide helps you make informed, data-driven decisions effortlessly.' },
  { src: book5, title: 'Charts in Power BI',text:'Become a visual storytelling expert with our guide to Power BI charts. Create clear, impactful visualizations that capture attention and drive action. Ideal for those looking to enhance their data presentations.' },
  { src: book6, title: 'R-Programming',text:'Dive into R programming with confidence. From basics to advanced data manipulation, this guide helps you handle complex data sets with precision. Perfect for bringing your data projects to life.' },
  { src: book7, title: 'SQL for Data Analytics',text: 'Navigate the world of data with SQL. Learn to query databases, extract valuable insights, and perform data manipulation effortlessly. This book makes SQL approachable for anyone keen on data analysis.'},
  { src: book8, title: 'Time Series in Python',text:'Predict the future with our Time Series in Python guide. Understand trends and build predictive models with ease. Master time series analysis and take your data skills to the next level.' },
  { src: book9, title: 'VBA for Macros',text: 'Streamline your workflow with VBA for Macros. Learn to write macros that automate repetitive tasks and enhance productivity. This book helps you turn Excel tasks into efficient processes.'},
  { src: book10, title: 'Data Visualization',text: 'Transform your data into compelling visuals. Learn the principles of effective data visualization and create charts that tell impactful stories. Perfect for anyone looking to communicate data insights beautifully.'}
];

const BookCards = () => {
  return (
    <section id='books' style={{marginTop: '5rem'}}>
    
    <Container>
      <div><h2 style={{fontFamily: "Margarine, sans-serif"}}>Books</h2><hr /></div>
      <Row>
        {books.map((book, index) => (
          <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
            <Card>
              <Card.Img variant="top" src={book.src} />
              <Card.Body>
                <Card.Title>{book.title}</Card.Title>
                <Card.Text>{book.text}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
    </section>
  );
};

export default BookCards;
