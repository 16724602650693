import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import img1 from '../img/img1.webp';
import img2 from '../img/img2.webp';
import img3 from '../img/img3.webp';
import img4 from '../img/img4.svg';
import img5 from '../img/img5.webp';
import img6 from '../img/img6.webp';
import './AlternatingRows.css'; 

const AlternatingRows = () => {
  const content = [
    {
      imgSrc: img1,
      title: 'Resume Building.',
      text: 'Transform your resume into a data science magnet. Highlight your key projects, showcase your analytical skills, and tailor your experience to catch the eye of top employers in the data-driven industry.',
    },
    {
      imgSrc: img2,
      title:'Industry Mentorship Session',
      text: "Connect with industry veterans and gain insights that textbooks can't offer. Our mentors will guide you through real-world challenges and provide personalized advice to advance your data science career.",
    },
    {
      imgSrc: img3,
      title: 'General Aptitude Preparation Sessions',
      text: 'Sharpen your analytical and problem-solving skills with our intensive preparation sessions. Master the quantitative and logical reasoning required to excel in the data science field.',
    },
    {
      imgSrc: img4,
      title: 'GitHub Repository Building',
      text: 'Build a GitHub repository that stands out. Showcase your data science projects, coding expertise, and version control skills, making a lasting impression on hiring managers.',
    },
    {
      imgSrc: img5,
      title: 'LinkedIn Profile Building',
      text: 'Create a LinkedIn profile that tells your data science story. Highlight your achievements, network with industry leaders, and position yourself for exciting career opportunities in the data-centric world.',
    },
    {
      imgSrc: img6,
      title: 'Mock Interview',
      text: 'Ace your data science interviews with our mock sessions. Practice with real-world scenarios, receive expert feedback, and walk into your interviews with confidence.',
    },
  ];

  const titleStyle = {
    fontFamily: 'Margarine, sans-serif',
    margin: '2rem 0 0 0',
  };

  const textStyle = {
    fontFamily: 'Margarine, Calibri',
    fontWeight: '100',
    fontStyle: 'normal',
    fontSize: 'large',
  };

  return (
    <Container>
      <div>
        <h2 style={titleStyle}>Job Preparation</h2>
      <hr style={{width: '5%',
    border: '2px solid #007bff',
    margin: '1rem auto'}} />
      </div>
      <hr />
      {content.map((item, index) => (
        <Row key={index} className="my-2 custom-row">
          <Col md={6} xs={12} className={`order-1 order-md-${index % 2 === 0 ? '1' : '2'}`}>
            <img src={item.imgSrc} alt={item.title} className="img-fluid" />
          </Col>
          <Col md={6} xs={12} className={`order-2 order-md-${index % 2 === 0 ? '2' : '1'} d-flex flex-column align-items-center justify-content-center`}>
            <h3 style={{ fontFamily: 'Margarine' }}>{item.title}</h3>
            <p style={textStyle}>{item.text}</p>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default AlternatingRows;
