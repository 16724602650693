import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import NavigationBar from './components/Navbar';
import Home from './components/Home';
import Project from './components/Project';
import Curriculum from './components/Curriculum';
import Courses from './components/Course';
import AlternatingRows from './components/JobPrep';
import OurTeam  from './components/OurTeam';
import Bonus  from './components/Bonus';
import Foot  from './components/Footer';
import './App.css';
import Review  from './components/Review';
import Bookcards  from './components/Bookcards';
import FAQ from "./components/Faq";
import Privacy from "./components/Privacy";
import Contact  from "./components/Contact";
import PopupForm from "./components/Popupform";
import { Feature } from "./components/Feature";

function App() {
  return (
    <Router>
    <div className="App">
    <ScrollToTop />
      <PopupForm/>
      <NavigationBar/>
    <Routes>
      <Route  path="/"
            element={
              <>      
      <Home/>
      <Courses/>
      <Project/>    
      <Curriculum/>
      <AlternatingRows/>
      
      <Bonus/>
      <Contact/>
      <Feature/>
      <Review/>
      
      </>
    }
    />
      <Route path="/book" element={<Bookcards/>}/>
      <Route path="/contact" element={<Contact/>}/>
      <Route path="/experts" element={<OurTeam/>}/>
      <Route path="/faq" element={<FAQ/>}/> 
      <Route path="/privacy" element={<Privacy/>}/> 
     
      </Routes>      
      <Foot/>
      </div>
    </Router>
  );
}

export default App;
