import React from 'react';
import { Carousel, Card, Row, Col } from 'react-bootstrap';
import C1 from '../img/c1.webp';
import C2 from '../img/c2.webp';
import C3 from '../img/c3.svg';
import C4 from '../img/c4.webp';
import C5 from '../img/c5.webp';
import C6 from '../img/c6.webp';
import C7 from '../img/c7.webp';
import './Curriculum.css';

const Curriculum = () => {
  const lectures = [
    { 
      title: 'Advance Excel And Data Analytics', 
      text:'Learn to use powerful Excel features like advanced formulas, pivot tables, and data cleaning tools to analyze and understand large sets of data',
      duration: '30 mins', 
      image: C1 
    },
    { 
      title: 'VBA Macros', 
      text:'Discover how to write and use Visual Basic for Applications (VBA) to automate repetitive tasks in Excel, saving time and reducing errors',
      duration: '45 mins', 
      image: C2 
    },
    { 
      title: 'SQL For Data Analytics', 
      text:'Understand how to write SQL queries to retrieve, organize, and analyze data from relational databases',
      duration: '40 mins', 
      image: C3 
    },
    {
      title: 'Data Analytics & Visualization with Tableau',
      text:'Get hands-on experience creating visual data reports and interactive dashboards with Tableau to make data insights easy to understand',
      duration: '50 mins',
      image: C4   
    },
    {
      title: 'Data Analytics & Visualization with PowerBi',
      text:'Learn to use PowerBi to build dynamic data reports and dashboards, helping you visualize and interpret data trends and patterns',
      duration: '50 mins',
      image: C5   
    },
    {
      title: 'Data Science with Python (Including Machine Learning)',
      text:'Explore data science with Python, learning how to handle data, perform analysis, and use machine learning techniques to build predictive models',
      duration: '50 mins',
      image: C6  
    },
    {
      title: 'Data Science with R (Including Machine Learning)',
      text:'Get into data science with R by learning how to analyze data statistically and use machine learning to make predictions',
      duration: '50 mins',
      image: C7  
    },
  ];

  return (
    <section id="curriculum">
      <div className="curriculum-carousel">
        <div className='section-title'><h2>Our Curriculum</h2><hr /></div>
        <Carousel infinite="true" indicators={false}>
          {lectures.map((lecture, index) => (
            <Carousel.Item key={index}>
              <Row style={{justifyContent: 'center'}}>
                <Col sm={4}>
                  <Card className="text-center">
                    <Card.Img variant="top" src={lecture.image} alt={lecture.title} />
                    <Card.Body>
                      <Card.Title>{lecture.title}</Card.Title>
                      <Card.Text>
                        {lecture.text}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default Curriculum;
