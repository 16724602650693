import React from 'react';
import './Home.css';
import { ArrowRight } from 'react-bootstrap-icons';

const Home = () => {
  return (
    <section id='home'>
    <div className="home-container">
      <div className="parallax"></div>
      <div className="content">
        <h1>Transform Your Career with Data Science 360 Course!</h1>
        <p style={{fontFamily: 'Margarine, calibri',fontSize: '1.5rem',margin: '20px 0'}}>Fuel Your Passion into Expertise!</p>
        <button className="cta-button" onClick={()=> document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })}>Enroll Now <ArrowRight/></button>
      </div>
    </div>
    </section>
  );
};

export default Home;
