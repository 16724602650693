import { Container, Row, Col, Table } from "react-bootstrap";
import "./Feature.css";
import { Check } from "react-bootstrap-icons";
import { X } from "react-bootstrap-icons";

export const Feature = () => {
  return (
    <section className="feature" id="feature">
      <Container>
        <Row>
          <div className="course-title">
            <h2
              style={{
                fontFamily: "Margarine, sans-serif",
                fontStyle: "normal",
              }}
            >
              Features
            </h2><hr style={{width: '5%',
    border: '2px solid #007bff',
    margin: '1rem auto'}}/>
          </div>
          <hr />
          <Col>
            <Table responsive>
              <thead>
                <tr>
                  <th>Feature</th>
                  <th>Udemy</th>
                  <th>Coursera</th>
                  <th>Scaler</th>
                  <th>Cloud ML</th>
                  <th>Brainalyst</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Afordable Course</th>
                  <th>
                    <Check style={{color: 'green'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                    <Check style={{color: 'green'}}/>
                  </th>
                  <th>
                    <Check style={{color: 'green'}}/>
                  </th>
                </tr>
                <tr>
                  <th>Hands on Learning</th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                    <Check style={{color: 'green'}}/>
                  </th>
                  <th>
                    <Check style={{color: 'green'}}/>
                  </th>
                  <th>
                    <Check style={{color: 'green'}}/>
                  </th>
                </tr>
                <tr>
                  <th>1:1 Mentorship</th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                    <Check style={{color: 'green'}}/>
                  </th>
                </tr>
                <tr>
                  <th>Live 1:1 Doubt Sessions</th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                    <Check style={{color: 'green'}}/>
                  </th>
                  <th>
                    <Check style={{color: 'green'}}/>
                  </th>
                </tr>
                <tr>
                  <th>Aptitude Test Training</th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                    <Check style={{color: 'green'}}/>
                  </th>
                  <th>
                    <Check style={{color: 'green'}}/>
                  </th>
                </tr>
                <tr>
                  <th>Live Interactive Online Classes</th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                    <Check style={{color: 'green'}}/>
                  </th>
                </tr>
                <tr>
                  <th>100% Paid Internship* On Completion</th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                    <Check style={{color: 'green'}}/>
                  </th>
                </tr>
                <tr>
                  <th>LMS Access After Completion</th>
                  <th>
                    <Check style={{color: 'green'}}/>
                  </th>
                  <th>
                    <Check style={{color: 'green'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                    <Check style={{color: 'green'}}/>
                  </th>
                  <th>
                    <Check style={{color: 'green'}}/>
                  </th>
                </tr>
                <tr>
                  <th>Dedicated Course Books</th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                   <X style={{color: 'red'}}/>
                  </th>
                  <th>
                    <Check style={{color: 'green'}}/>
                  </th>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </section>
  );
};