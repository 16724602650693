import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom";
import logo from '../img/logo_color-removebg-preview.png';
import CurriculumModal from "./Modal";
import CourseDetailsModal from "./Modal2";
import '../components/Navbar.css';


function NavigationBar() {
  const [modalShow, setModalShow] = useState(false);
  const [courseDetailsModalShow, setCourseDetailsModalShow] = useState(false);

  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);

  const handleCourseDetailsModalClose = () => setCourseDetailsModalShow(false);
  const handleCourseDetailsModalShow = () => setCourseDetailsModalShow(true);

  const [navbarClass, setNavbarClass] = useState('navbar-transparent');
  const [expanded, setExpanded] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setNavbarClass('navbar-colored');
    } else {
      setNavbarClass('navbar-transparent');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className={`fixed-top ${navbarClass} ${expanded ? 'navbar-expanded' : ''}`}
        expanded={expanded}
        onToggle={(isExpanded) => setExpanded(isExpanded)}
        style={{ fontFamily: "Margarine, calibri" }}
      >
        <Container>
          <Navbar.Brand href="https://dsp.brainalyst.in/">
            <img className="brand-logo-img" src={logo} alt="logo" style={{ width: '7rem' }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">Home</Nav.Link>
              <Nav.Link as={Link} to="/book">Books</Nav.Link>
              <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
              <Nav.Link as={Link} to="/experts">Team</Nav.Link>
              <Nav.Link as={Link} to="/privacy">Privacy</Nav.Link>
              <Nav.Link as={Link} to="/faq">FAQ</Nav.Link>
              <NavDropdown title="Download" id="basic-nav-dropdown" style={{ fontFamily: "Margarine, calibri" }}>
                <NavDropdown.Item onClick={handleModalShow}>Curriculum</NavDropdown.Item>
                <NavDropdown.Item onClick={handleCourseDetailsModalShow}>Course</NavDropdown.Item>             
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <CurriculumModal show={modalShow} handleClose={handleModalClose} />
      <CourseDetailsModal show={courseDetailsModalShow} handleClose={handleCourseDetailsModalClose} />
    </>
  );
}

export default NavigationBar;
