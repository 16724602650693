import React from "react";
import { Container, Row } from "react-bootstrap";
import {Youtube,Facebook,Linkedin,Instagram } from 'react-bootstrap-icons'; 


 const Foot = () => {
  return (
    <footer className="footer" id="footer">
      <Container fluid>
        <Row>
          <div style={{display: 'flex',justifyContent:'center',height: '3rem',alignItems: 'center',background: '#dbd9d9'}}>
              <a href="https://www.facebook.com/Brainalystindia/" style={{paddingRight: '5px',color: '#121212',paddingLeft: '5px'}}><Facebook/></a>
              <a href="https://www.instagram.com/brainalyst_india/" style={{paddingRight: '5px',color: '#121212',paddingLeft: '5px'}}><Instagram /></a> 
              <a href="https://www.linkedin.com/company/brainalyst/mycompany/" style={{paddingRight: '5px',color: '#121212',paddingLeft: '5px'}}><Linkedin/></a>
              <a href="https://www.youtube.com/channel/UCphzWgX1Inhm3VAaSBDGwuw" style={{paddingRight: '5px',color: '#121212',paddingLeft: '5px'}}><Youtube/></a> 
          </div>
        <div className="copyDiv" style={{ display: 'flex',justifyContent: 'center',alignItems: 'center', textDecoration: "none", color: "#121212",background: '#808080cf',fontFamily: 'margaine sans-serif',fontWeight: '400',fontStyle: 'normal',height: '3rem' }}>© Brainalyst 2021-2024</div>
        </Row>
        </Container>
    </footer>
  );
};
export default Foot;