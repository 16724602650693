import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import bonusImg2 from "../img/internship.svg";
import bonusImg3 from "../img/bonus reffral.svg";
import bonusImg4 from "../img/certificate.svg";
import bonusImg5 from "../img/coding.svg";
import bonusImg6 from "../img/life time acess.svg";
import "./Bonus.css";


const Bonus = () => {
  return (
    <section className="bonus" id="bonus">
      <Container>
        <Row>
          <div className="bonus-title">
            <h2
              style={{                
                marginTop: '3rem'             
              }}
            >
              Perks & Benefits
            </h2>
              <hr style={{width: '5%',
    border: '2px solid #007bff',
    margin: '1rem auto',marginBottom: '2rem'}} />
          </div>

          {[
            {
              img: bonusImg2,
              alt:"bonusimg2",
              title: "100% Paid Internship*",
              text: "Secure a fully paid internship and gain valuable real-world experience. Kickstart your career with financial support and hands-on learning opportunities. This is your chance to dive into the industry with confidence. Start building your future today!",
            },
            {
              img: bonusImg5,alt:"bonusimg5",
              title:
                "Hackathons & Webinars",text: "Unlock access to exciting hackathons, coding contests, and webinars upon completion. Enhance your skills and connect with industry experts through exclusive events. These opportunities will help you stand out and grow your professional network. Get ready for exciting challenges!"
            },
            {
              img: bonusImg4,alt:"bonusimg4",
              title: "Prestigious Certification",text: "Earn a distinguished course completion and internship certificate. This certificate showcases your dedication and skills to future employers. It’s a testament to your hard work and expertise in the field. Proudly display your achievements and advance your career!"
            },
            { img: bonusImg6,alt:"bonusimg6", title: "Lifetime LMS Access",text: "Enjoy lifetime access to our Learning Management System. Revisit course materials and stay updated with the latest insights in data science. Learn at your own pace and ensure you’re always at the cutting edge of the field. Your learning journey continues forever!" },
            { img: bonusImg3,alt:"bonusimg3", title: " Referral Rewards",text: "Refer friends to our course and earn exciting bonuses. Share the opportunity and help others start their data science journey while enjoying rewards. Your referrals not only benefit from the course but also contribute to your own success. Spread the word and reap the rewards!" },
          ].map((bonus, index) => (
            <Col
              key={index}
              xs={12}
              sm={6}
              md={4}
              lg={4}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "3rem",
              }}
            >
              <Card className="bonus-card">
                <Card.Img
                  variant="top"
                  src={bonus.img}
                  alt="bonusImg"
                  className="course-img"
                  style={{ height: "7rem" }}
                />
                <Card.Body>
                  <Card.Title className="bonus-title" style={{fontFamily: 'Margarine,calibri',}}>
                    {bonus.title}
                  </Card.Title>
                  <Card.Text style={{fontFamily: 'Margarine,calibri'}}>{bonus.text}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};
export default Bonus;